import { env } from "&environments/env";
import { ChartConfigBase } from "@otimo/highcharts-xmlreport";

export class PortalChartConfig extends ChartConfigBase {
    constructor(baseUrl: string) {
        super();
        this.apiBaseUrl = baseUrl;
    }
    apiBaseUrl: string;

    highChartUrl = "api/reports/GenerateHighChart";
    tableUrl = "api/reports/GenerateExcel";

    periodstart: string[];
    periodslut: string[];

    kon: string[];
    aldersgrupper: number[];
    enhet: string[];
    kommun: string[];
    avlidenplats: string[];
    dodsfallVantat: number[];
    covid: number[];
    hemtjanst: number[];
    vardtid: number[];

    diagCancer: number[];
    diagHjartsjukdom: number[];
    diagLungsjukdom: number[];
    diagDemens: number[];
    diagStroke: number[];
    diagAnnanNeurologisksjukdom: number[];
    diagInfektion: number[];
    diagDiabetes: number[];
    diagFraktur: number[];
    diagMultipel: number[];
    diagOvrigt: number[];

    extAndlig: number[];
    extAnnanSjukhusenhet: number[];
    extPallTeam: number[];
    extParamedicinare: number[];
    extSmartenhet: number[];
    extAnnan: number[];

    vardAvSPV: number[];
    vardAvAHS: number[];
    vardAvOkant: number[];
}
