<div id="urval-accordion" #urvalAccordion>

    <ngb-accordion #acc="ngbAccordion" activeIds="header-tab" (panelChange)="tabToggle($event)" [destroyOnHide]="false"
                   [ngbTooltip]="skipsAllParams() ? ('urval.does-not-apply'|translate) : ''" placement="top">
        <ngb-panel id="header-tab">
            <ng-template ngbPanelHeader></ng-template>
            <ng-template ngbPanelContent>
                <div class="row"
                     [style.pointer-events]="skipsAllParams() ? 'none' : 'all'"
                     [style.opacity]="skipsAllParams() ? '0.5' : ''">
                    <div class="col-12">
                        <div id="top-links">
                            <button type="button" class="btn btn-outline-info rounded-circle" (click)="shareReport()"
                                    [ngbTooltip]="'urval.share-report'|translate" placement="bottom">
                                <i class="fas fa-lg fa-share"></i>
                            </button>
                            <button *ngIf="loggedIn" type="button" class="btn btn-outline-primary rounded-circle" (click)="saveUserReport()"
                                    [ngbTooltip]="'urval.save-report'|translate" placement="bottom">
                                <i class="fas fa-lg fa-save"></i>
                            </button>
                            <button type="button" class="btn btn-outline-secondary rounded-circle" (click)="open(urvalModal)"
                                    [ngbTooltip]="'urval.list'|translate" placement="bottom">
                                <i class="fas fa-lg fa-list-ul"></i>
                            </button>
                            <button type="button" class="btn btn-outline-secondary rounded-circle" (click)="cleanParameters()"
                                    [ngbTooltip]="'urval.clear'|translate" placement="bottom">
                                <i class="fas fa-lg fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12">
                        <h2 id="urval-header" class="mb-3 text-center">{{ 'urval.header'|translate }}</h2>
                    </div>
                    <div class="col-12">
                        <div class="form-check ms-1">
                            <input type="checkbox" class="form-check-input"
                                   id="saveFiltersForAllReports" value="saveFiltersForAllReports"
                                   [(ngModel)]="saveFiltersForAllReports"
                                   (change)="changedSaveForAllReports.emit(saveFiltersForAllReports)">
                            <label class="form-check-label" for="saveFiltersForAllReports">
                                {{ 'urval.save-for-all'|translate }}
                            </label>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="period-tab" *ngIf="showThisParam('startdat') && showThisParam('stopdat')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.period.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>

                <div id="PeriodFrom" class="mb-3 me-2">
                    <label>{{'urval.period.from'|translate}}</label>
                    <div class="row">
                        <div class="col-5 pe-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text px-2" for="startYear">{{'urval.period.year'|translate}}</label>
                                </div>
                                <select class="form-select form-select-sm no-caret px-2" id="startYear" [(ngModel)]="startYearData.data" (change)="periodSelectChange()">
                                    <option value="{{year}}" *ngFor="let year of startYears">{{year}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-7 ps-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text px-2" for="startMonth">{{'urval.period.month'|translate}}</label>
                                </div>
                                <select class="form-select form-select-sm no-caret px-2" id="startMonth" [(ngModel)]="startMonthData.data" (change)="periodSelectChange()">
                                    <option value="{{month.key}}" *ngFor="let month of startMonths">{{month.value}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="PeriodTo" class="mb-3 me-2">
                    <label>{{'urval.period.to'|translate}}</label>
                    <div class="row">
                        <div class="col-5 pe-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text px-2" for="stopYear">{{'urval.period.year'|translate}}</label>
                                </div>
                                <select class="form-select form-select-sm no-caret px-2" id="stopYear" [(ngModel)]="stopYearData.data" (change)="periodSelectChange()">
                                    <option value="{{year}}" *ngFor="let year of stopYears">{{year}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-7 ps-0">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <label class="input-group-text px-2" for="stopMonth">{{'urval.period.month'|translate}}</label>
                                </div>
                                <select class="form-select form-select-sm no-caret px-2" id="stopMonth" [(ngModel)]="stopMonthData.data" (change)="periodSelectChange()">
                                    <option value="{{month.key}}" *ngFor="let month of stopMonths">{{month.value}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </ngb-panel>

        <ngb-panel id="kon-tab" *ngIf="showThisParam('kon')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.gender.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="konParamData"
                                 [treeViewData]="konViewData"
                                 (updatedParam)="updateParam($event)"></treeview-single>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="alder-tab" *ngIf="showThisParam('aldersgrupper')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.alder.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="alderParamData"
                                 [treeViewData]="alderViewData"
                                 (updatedParam)="updateParam($event)"></treeview-single>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="covid-tab" *ngIf="showThisParam('covid')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.covid.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="covidParamData"
                                 [treeViewData]="covidViewData"
                                 (updatedParam)="updateParam($event)"></treeview-single>

                <hr class="my-2" />
                <i class="text-muted" style="font-size:95%">{{'urval.covid.info'|translate}}</i>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="enhet-tab" *ngIf="showThisParam('enhet') && enhetViewData.serverData != null">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.enhet.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <div *ngIf="enhetViewData.serverData.length > 0">
                    <treeview-single [paramData]="enhetParamData"
                                     [treeViewData]="enhetViewData"
                                     [showFilter]="true"
                                     (updatedParam)="updateParam($event)"></treeview-single>
                </div>
                <div *ngIf="enhetViewData.serverData.length == 0">
                    <i>{{'urval.enhet.no-available'|translate}}</i>
                </div>
            </ng-template>
        </ngb-panel>

        <!-- IS 230524: "Folkbokföringskommun" tas bort tillfälligt, kommer tillbaka när "Verksamhet" försvunnit  (Sanna) -->
        <!-- IS 230815: "Folkbokföringskommun" tillbaka, men endast på rapporten "Täckningsgrad"  (Maria) -->
        <ngb-panel id="kommun-tab" *ngIf="showThisParam('kommun')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.kommun.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="kommunParamData"
                                 [treeViewData]="kommunViewData"
                                 [showFilter]="true"
                                 (updatedParam)="updateParam($event)"></treeview-single>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="avlidenplats-tab" *ngIf="showThisParam('avlidenplats')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.avlidenplats.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="avlidenplatsParamData"
                                 [treeViewData]="avlidenplatsViewData"
                                 (updatedParam)="updateParam($event)"></treeview-single>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="vardAv-tab" *ngIf="showThisParam('vardAv')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.vard-av.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="form-default ps-2">
                    <div *ngFor="let vardAvParam of (vardAvParamDatas | async); index as i">
                        <checkbox-item [text]="(vardAvViewDatas|async)[i]?.title" value="1"
                                       [checked]="vardAvParam.data ? vardAvParam.data[0] : 0"
                                       (clicked)="setVardAv($event, vardAvParam.name)"></checkbox-item>
                    </div>
                </div>
                <i class="text-muted" style="font-size:95%">{{'urval.vard-av.info'|translate}}</i>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="hemtjanst-tab" *ngIf="showThisParam('hemtjanst')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.hemtjanst.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="hemtjanstParamData"
                                 [treeViewData]="hemtjanstViewData"
                                 (updatedParam)="updateParam($event)"></treeview-single>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="vardtid-tab" *ngIf="showThisParam('vardtid')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.vardtid.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="vardtidParamData"
                                 [treeViewData]="vardtidViewData"
                                 (updatedParam)="updateParam($event)"></treeview-single>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="dodsfallvantat-tab" *ngIf="showThisParam('dodsfallVantat')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.dodsfall-vantat.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <treeview-single [paramData]="dodsfallVantatParamData"
                                 [treeViewData]="dodsfallVantatViewData"
                                 (updatedParam)="updateParam($event)"></treeview-single>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="diagnos-tab" *ngIf="showThisParam('diagnosgrupp')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.diagnosgrupp.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="form-default ps-2">
                    <div *ngFor="let diagnosParam of (diagnosParamDatas | async); index as i">
                        <checkbox-item [text]="(diagnosViewDatas|async)[i]?.title" value="1"
                                       [checked]="diagnosParam.data ? diagnosParam.data[0] : 0"
                                       (clicked)="setDiagnosGrupp($event, diagnosParam.name)"></checkbox-item>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="externKonsult-tab" *ngIf="showThisParam('externKonsult')">
            <ng-template ngbPanelTitle>
                <h5>{{'urval.extern-konsult.header'|translate}}</h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="form-default ps-2">
                    <div *ngFor="let extParam of (externKonsultParamDatas | async); index as i">
                        <checkbox-item [text]="(externKonsultViewDatas|async)[i]?.title" value="1"
                                       [checked]="extParam.data ? extParam.data[0] : 0"
                                       (clicked)="setExternKonsultGrupp($event, extParam.name)"></checkbox-item>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>

    </ngb-accordion>

</div>


<ng-template #urvalModal let-c="close" let-d="dismiss">
    <div class="modal-header py-2">
        <h5 class="modal-title text-center">{{'urval.my-selections.header'|translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <display-param-text-data [paramData]="startdatData" *ngIf="showThisParam('startdat')"></display-param-text-data>
        <display-param-text-data [paramData]="stopdatData" *ngIf="showThisParam('stopdat')"></display-param-text-data>
        <display-param-treeview-data [paramData]="konParamData" [treeViewData]="konViewData" *ngIf="showThisParam('kon')"></display-param-treeview-data>
        <display-param-treeview-data [paramData]="alderParamData" [treeViewData]="alderViewData" *ngIf="showThisParam('aldersgrupper')"></display-param-treeview-data>
        <display-param-treeview-data [paramData]="covidParamData" [treeViewData]="covidViewData" *ngIf="showThisParam('covid')"></display-param-treeview-data>
        <display-param-treeview-data [paramData]="enhetParamData" [treeViewData]="enhetViewData" *ngIf="showThisParam('enhet') && enhetViewData.serverData != null"></display-param-treeview-data>
        <display-param-treeview-data [paramData]="kommunParamData" [treeViewData]="kommunViewData" *ngIf="showThisParam('kommun')"></display-param-treeview-data>
        <display-param-treeview-data [paramData]="avlidenplatsParamData" [treeViewData]="avlidenplatsViewData" *ngIf="showThisParam('avlidenplats')"></display-param-treeview-data>
        <display-param-grouped-treeview-data [paramTitle]="'urval.vard-av.header'|translate" *ngIf="showThisParam('vardAv')" 
                                             [paramDatas]="vardAvParamDatas | async" [treeViewDatas]="vardAvViewDatas | async"
                                             ></display-param-grouped-treeview-data>
        <display-param-treeview-data [paramData]="hemtjanstParamData" [treeViewData]="hemtjanstViewData" *ngIf="showThisParam('hemtjanst')"></display-param-treeview-data>
        <display-param-treeview-data [paramData]="vardtidParamData" [treeViewData]="vardtidViewData" *ngIf="showThisParam('vardtid')"></display-param-treeview-data>
        <display-param-treeview-data [paramData]="dodsfallVantatParamData" [treeViewData]="dodsfallVantatViewData" *ngIf="showThisParam('dodsfallVantat')"></display-param-treeview-data>
        <display-param-grouped-treeview-data [paramTitle]="'urval.diagnosgrupp.header'|translate" *ngIf="showThisParam('diagnosgrupp')"
                                             [paramDatas]="diagnosParamDatas | async" [treeViewDatas]="diagnosViewDatas | async"
                                             ></display-param-grouped-treeview-data>
        <display-param-grouped-treeview-data [paramTitle]="'urval.extern-konsult.header'|translate" *ngIf="showThisParam('externKonsult')"
                                             [paramDatas]="externKonsultParamDatas | async" [treeViewDatas]="externKonsultViewDatas | async"
                                             ></display-param-grouped-treeview-data>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">{{'general.close'|translate}}</button>
    </div>
</ng-template>


<div id="print-params" hidden>
    <table class="table table-sm content-table">
        <thead><tr><th><h6>{{'urval.made-selections'|translate}}</h6></th></tr></thead>
        <tbody><tr><td>
            <parameter-list 
                [textParamDatas]="textParamDatas | async"
                [treeParamDatas]="treeParamDatas | async"
                [treeViewDatas]="treeViewDatas | async"
                [vardAvParamDatas]="vardAvParamDatas | async"
                [vardAvViewDatas]="vardAvViewDatas | async"
                [diagnosParamDatas]="diagnosParamDatas | async"
                [diagnosViewDatas]="diagnosViewDatas | async"
                [externKonsultParamDatas]="externKonsultParamDatas | async"
                [externKonsultViewDatas]="externKonsultViewDatas | async"
                ></parameter-list>
            </td></tr></tbody>
        <tfoot><tr><th>&ensp;</th></tr></tfoot>
    </table>
</div>
