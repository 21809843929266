<display-param-text-data *ngFor="let paramData of textParamDatas" 
                         [paramData]="paramData"></display-param-text-data>

<div *ngFor="let paramData of treeParamDatas; let i = index;">
    <display-param-treeview-data *ngIf="treeViewDatas[i]?.serverData != null"
                                 [paramData]="paramData"
                                 [treeViewData]="treeViewDatas[i]"></display-param-treeview-data>
</div>

<display-param-grouped-treeview-data paramTitle="Vård av"
                                     [paramDatas]="vardAvParamDatas"
                                     [treeViewDatas]="vardAvViewDatas"></display-param-grouped-treeview-data>

<display-param-grouped-treeview-data paramTitle="Diagnosgrupp"
                                    [paramDatas]="diagnosParamDatas"
                                    [treeViewDatas]="diagnosViewDatas"></display-param-grouped-treeview-data>

<display-param-grouped-treeview-data paramTitle="Extern konsult"
                                     [paramDatas]="externKonsultParamDatas"
                                     [treeViewDatas]="externKonsultViewDatas"></display-param-grouped-treeview-data>